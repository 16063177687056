import React from 'react';

import { Footer } from 'components/global/Footer';
import { LandingPageWrapper } from 'components/global/LandingPageWrapper';
import { Head } from '../Homepage/components/Head';
import { HolidayBadge } from 'components/global/HolidayBadge';
import { HeroPop } from 'components/modules/pop-redesign/HeroPop';
import { HOLIDAY_HERO_CONTENT } from './content';
import { Nav } from 'components/global/Nav';

export const CroQAPage = () => {
  return (
    <LandingPageWrapper>
      <Head />
      <Nav holidayPromoBanner={<HolidayBadge />} />
      <HeroPop {...HOLIDAY_HERO_CONTENT} />

      {/* Add any components you need to QA */}

      <Footer
        disclaimer={
          <p>
            <sup>▵</sup> Online reviews as of 12/03/2021 *Among 856 customers
            subscribed to Curology for 3+ months. Self-reported. Subscription
            required and subject to consultation. <sup>1</sup>Offer available
            until 1/7/24. Advertised price not inclusive of handling fees.
            Subject to consultation. New subscribers only. Trial-size products
            only. Only Rx products are personalized.
          </p>
        }
      />
    </LandingPageWrapper>
  );
};
