import React from 'react';
import {
  NEW_YEAR_PROMO_BANNER_CONTENT,
  newYearPromoBannerStyles,
  NavBanner,
} from 'components/global/Nav/components';

export const HolidayBadge = () => {
  const promoInfo = {
    badgeContent: NEW_YEAR_PROMO_BANNER_CONTENT,
    styles: newYearPromoBannerStyles,
  };

  const { badgeContent } = promoInfo;

  return <NavBanner {...promoInfo} {...badgeContent} />;
};
