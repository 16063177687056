import {
  HeroPopProps,
  POP_REDESIGN_HERO_CONTENT,
} from 'components/modules/pop-redesign/HeroPop';
import { StaticImage } from 'gatsby-plugin-image';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const HOLIDAY_HERO_CONTENT: HeroPopProps = {
  title: 'New Year, New Glowing Skin',
  subtitle:
    'Start the New Year off with glowing skin. For a limited time, you can get your first Curology box for just $5.<sup>1</sup>',
  theme: 'secondary',
  ctaButton: {
    buttonText: 'Get my formula',
  },
  images: [
    <StaticImage
      src="../images/desktop@1x.png"
      alt="Four Curology products — Gentle Cleanser, Cream Moisturizer, Everyday Sunscreen (spf 30) and Emergency Spot Patches —  against a beige background"
      css={desktopOnly()}
      loading="eager"
    />,
    <StaticImage
      src="../images/mobile@1x.png"
      alt="Four Curology products — Gentle Cleanser, Cream Moisturizer, Everyday Sunscreen (spf 30) and Emergency Spot Patches —  against a beige background"
      css={mobileOnly()}
      loading="eager"
    />,
  ],
  showStats: true,
  efficacyStats: POP_REDESIGN_HERO_CONTENT.efficacyStats,
  backgroundColor: '#1d1d44',
};
